<template>
  <div>
    <div
      v-if="list.length > 0"
      class="profiles-results"
    >
      <div
        v-for="item in list"
        :key="item.id"
        class="profile"
      >
        <span class="name">{{ item.name }}</span>
        <div class="icons-actions">
          <div
            class="icon-action cursor-pointer"
            @click="openModalVinculate(item)"
          >
            <ProfileIcon class="icon" />
            <span>{{ item.users_quantity }}</span>
          </div>
          <div
            class="icon-action"
            @click="openAccessModal(item)"
          >
            <KeyIcon class="icon" />
            <span>{{ item.securable_permission_quantity }}</span>
          </div>
          <div class="icon-action cursor-pointer">
            <OptionsIcon
              class="icon"
              tabindex="0"
              :id="'action-' + item.id"
              @click="openActionsList(item)"
            />
            <Popover
              :items="actionList"
              :target="'action-' + item.id"
              placement="bottomright"
              custom-position="action-button"
              triggers="focus"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="no-results-found"
    >
      <NoResultIcon class="icon-no-results" />
      <span>{{ $t('ProfilesPage.NoResultsFound') }}</span>
    </div>
  </div>
</template>

<script>
  import { mapMutations, mapState } from 'vuex';
  import KeyIcon from '@core/assets/icons/key-icon-2.svg';
  import ProfileIcon from '@core/assets/icons/profile-icon-filled.svg';
  import OptionsIcon from '@core/assets/icons/options-icon.svg';
  import DeleteIcon from '@core/assets/icons/delete-icon.svg';
  import EditIcon from '@core/assets/icons/edit-icon.svg';
  import DuplicateIcon from '@core/assets/icons/duplicate-icon.svg';
  import NoResultIcon from '@core/assets/icons/no-result-icon.svg';
  import Popover from '@core/layouts/layout-horizontal/components/Menu/Popover.vue';
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
  export default {
    data() {
      return {
        currentItem: null,
        actionList: [
          {
            title: 'ProfilesPage.Rename',
            icon: EditIcon,
            click: () => {
              this.openModal('edit');
            }
          },
          {
            title: 'ProfilesPage.Duplicate',
            icon: DuplicateIcon,
            click: () => {
              this.openModal('duplicate');
            }
          },
          {
            title: 'ProfilesPage.Delete',
            icon: DeleteIcon,
            click: () => {
              this.openDeleteModal();
            }
          }
        ]
      };
    },
    components: {
      ProfileIcon: () => import('@core/assets/icons/profile-icon-filled.svg'),
      KeyIcon: () => import('@core/assets/icons/key-icon-2.svg'),
      OptionsIcon: () => import('@core/assets/icons/options-icon.svg'),
      DeleteIcon: () => import('@core/assets/icons/delete-icon.svg'),
      EditIcon: () => import('@core/assets/icons/edit-icon.svg'),
      DuplicateIcon: () => import('@core/assets/icons/duplicate-icon.svg'),
      NoResultIcon: () => import('@core/assets/icons/no-result-icon.svg'),
      NoResultIcon,
      ProfileIcon,
      KeyIcon,
      OptionsIcon,
      DeleteIcon,
      EditIcon,
      DuplicateIcon,
      Popover
    },
    props: {
      list: {
        type: Array,
        default: []
      },
      prefix: {
        type: String,
        default: ''
      }
    },
    methods: {
      ...mapMutations('profile', [
        'UPDATE_MODAL_EDIT_DELETE_TOGGLE',
        'UPDATE_PROFILE_SELECTED',
        'UPDATE_TYPE_MODAL',
        'UPDATE_MODAL_TITLE',
        'UPDATE_MODAL_ACCESS_TOGGLE',
        'UPDATE_MODAL_PEOPLE_TOGGLE'
      ]),
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      openModalVinculate(item) {
        this.UPDATE_MODAL_PEOPLE_TOGGLE(true);
        this.currentItem = item;
        this.UPDATE_PROFILE_SELECTED(item);
      },
      openActionsList(item) {
        this.currentItem = item;
        this.UPDATE_PROFILE_SELECTED(item);
      },
      openAccessModal(item) {
        this.UPDATE_MODAL_ACCESS_TOGGLE(true);
        this.UPDATE_PROFILE_SELECTED(item);
      },
      openModal(type) {
        this.UPDATE_TYPE_MODAL(type);
        this.UPDATE_MODAL_EDIT_DELETE_TOGGLE(true);
      },
      openDeleteModal() {
        this.$swal({
          title: this.$t('ProfilesPage.DeleteModalTitle'),
          showCloseButton: true,
          showCancelButton: true,
          cancelButtonText: this.$t('ProfilesPage.CancelButtonText'),
          confirmButtonText: this.$t('ProfilesPage.DeleteButtonText'),
          html: `<div>${this.$t('ProfilesPage.DeleteModalText1')} <b>${
            this.currentItem.name
          }</b>. ${this.$t('ProfilesPage.DeleteModalText2')}</div>`,
          customClass: {
            container: 'swal-conection',
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then((result) => {
          if (result.value) {
            this.callDeleteRoute();
          }
        });
      },
      callDeleteRoute() {
        this.$http2
          .delete(`/api/myconfig/profile/${this.currentItem.id}`)
          .then(() => {
            this.showToast(
              this.$t('IntegrationPage.success'),
              'SmileIcon',
              this.$t('ProfilesPage.DeletedProfile'),
              'success'
            );
            this.$emit('deletedProfile');
          })
          .catch((error) => {
            this.showToast(this.$t('Error'), 'FrownIcon', error.response.data.message, 'danger');
          });
      }
    },
    computed: {
      ...mapState('profile', {
        isModalEditRenameDeleteOpen: 'isModalEditRenameDeleteOpen',
        typeModalStore: 'typeModalStore'
      })
    }
  };
</script>

<style lang="scss">
  .swal-conection {
    .swal2-modal {
      padding: 0 !important;
      border-radius: 6px;
      width: 710px;

      .swal2-header {
        background-color: #d32f2f;
        padding: 20px 16px;
        border-radius: 6px 6px 0px 0px;
        flex-direction: row;
        justify-content: left;

        .swal2-title {
          color: #ffff;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          margin: 0;
        }

        .swal2-close {
          border-radius: 6px;
          width: 34px;
          background-color: #ffff;
          height: 34px;
          top: -5px;
          position: absolute;
          right: -5px;
          padding-bottom: 5px;
        }
      }

      .swal2-content {
        padding: 20px 16px;

        .swal2-html-container {
          font-weight: 400;
          text-align: left;
          font-size: 14px;
          line-height: 20px;
          color: #4c4541;
          display: flex !important;
        }
      }

      .swal2-actions {
        padding: 0 16px 20px 16px;
        display: flex;
        justify-content: right;

        .swal2-confirm {
          margin-left: 10px !important;
          order: 2;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #ffff;
          border-color: #974900 !important;
          background-color: #974900 !important;
          border-radius: 5px;

          &:hover {
            box-shadow: none !important;
          }
        }

        .swal2-cancel {
          order: 1;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #974900 !important;
          border-radius: 5px;
          border-color: transparent !important;

          &:hover {
            background-color: #ffede1 !important;
          }
        }
      }
    }
  }
  .profiles-results {
    .profile {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      gap: 8px;
      border-bottom: 1px solid #eee;
      margin-right: 8px;

      &:hover {
        background: #ffdbc4;
        color: #974900;
        border-color: #974900;

        .icons-actions {
          .icon-action {
            cursor: pointer;
            .icon {
              fill: #974900 !important;

              svg {
                g {
                  path {
                    fill: #974900;
                  }
                }
              }
            }
          }
        }
      }

      span {
        display: flex;
        flex: 1;
        &.name {
          color: #4c4541;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }
      }

      .icons-actions {
        display: flex;
        align-items: center;
        gap: 8px;

        .icon-action {
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 4px 8px;

          .icon {
            width: 14px;
            height: 14px;
            fill: #998f8a;

            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }

  .no-results-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    font-family: Raleway;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;

    .icon-no-results {
      width: 80px;
      height: 80px;
      fill: #4c4541;
    }
  }
</style>
