<template>
  <div
    v-if="isOpen"
    class="modal-attention-email swal2-container swal2-center swal-conection swal2-backdrop-show"
  >
    <div
      class="swal2-popup swal2-modal swal2-show"
      style="display: flex"
    >
      <div class="swal2-header position-relative">
        <div class="swal2-title">{{ $t('IntegrationPage.attention') }}</div>
        <button
          type="button"
          class="swal2-close d-flex"
          aria-label="Close this dialog"
          @click="UPDATE_MODAL_ATTENTION_EMAIL(false)"
        >
          ×
        </button>
      </div>
      <div class="swal2-content">
        <div class="subtitle">
          {{ $t('ProfilesPage.ModalAttentionText') }}
        </div>
        <div class="people-list">
          <div
            v-if="!!isToValidate && peopleList.find((e) => e.email === '')"
            class="error-message"
          >
            {{ $t('ProfilesPage.ModalAttentionError') }}
          </div>
          <div
            :class="[
              'people-block',
              !!isToValidate && peopleList.find((e) => e.email === '') ? 'is-invalid' : ''
            ]"
          >
            <div
              v-for="person in peopleList"
              :key="person.guid"
              class="person-row"
            >
              <div class="person-info">
                <div class="picture">
                  <img
                    v-if="person.photo"
                    :src="person.photo"
                    alt="User photo"
                  />
                  <AvatarPlaceholder
                    v-else
                    size="40px"
                    :text="picturePlaceholder(person.firstName, person.lastName)"
                  />
                </div>
                <div class="text-block">
                  <div class="people-name">{{ person.firstName }} {{ person.lastName }}</div>
                  <div class="people-code">{{ person.code }}</div>
                </div>
              </div>
              <div class="person-email">
                <b-form-input
                  :id="person.guid"
                  :value="person.email"
                  lazy-formatter
                  :formatter="
                    (e) => {
                      const hasEqualEmails =
                        peopleList.filter((e) => e.email && e.email === person.email).length > 1;
                      const hasEmptyFields = peopleList.find((e) => e.email === '');
                      if (e.trim() !== '' && !hasEqualEmails && !hasEmptyFields) {
                        isToValidate = false;
                      }

                      return e;
                    }
                  "
                  @change="(e) => (person.email = e)"
                  :state="verifyState(person)"
                  :placeholder="$t('SupportPage.Email')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swal2-actions">
        <button
          type="button"
          class="swal2-cancel btn btn-outline-danger ml-1 d-inline-block"
          @click="UPDATE_MODAL_ATTENTION_EMAIL(false)"
        >
          {{ $t('IntegrationPage.cancel') }}
        </button>
        <button
          type="button"
          class="swal2-confirm btn btn-primary d-inline-block"
          @click="onSave()"
        >
          {{ $t('IntegrationPage.Save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { BFormGroup, BFormInput, BCol } from 'bootstrap-vue';
  import { mapState, mapMutations } from 'vuex';
  import AvatarPlaceholder from '@/@core/components/avatar-placeholder/index.vue';

  export default {
    components: {
      BCol,
      BFormGroup,
      BFormInput,
      AvatarPlaceholder
    },
    props: {
      device: {
        type: String,
        default: 'desktop'
      }
    },
    data() {
      return {
        isOpen: false,
        isToValidate: false,
        flagEqualEmailName: false,
        peopleList: [
          {
            guid: 'teste',
            firstName: 'Fulano',
            lastName: '1',
            code: '1234',
            photo: null,
            email: ''
          },
          {
            guid: 'teste3',
            firstName: 'Fulano',
            lastName: '22',
            code: '12343',
            photo: null,
            email: ''
          },
          {
            guid: 'teste4',
            firstName: 'Fulano',
            lastName: '3',
            code: '121134',
            photo: null,
            email: ''
          },
          {
            guid: 'teste512',
            firstName: 'Fulano',
            lastName: '52',
            code: '1234123',
            photo: null,
            email: ''
          },
          {
            guid: 'teste5213',
            firstName: 'Fulano',
            lastName: '5321',
            code: '1234123',
            photo: null,
            email: ''
          },
          {
            guid: 'teste2225',
            firstName: 'Fulano',
            lastName: '53331',
            code: '1234123',
            photo: null,
            email: ''
          }
        ]
      };
    },
    mounted() {
      this.UPDATE_MODAL_ATTENTION_EMAIL(true);
    },
    methods: {
      ...mapMutations('profile', ['UPDATE_MODAL_ATTENTION_EMAIL']),
      clearFields() {},
      clearValidations() {
        this.flagEqualEmailName = false;
      },
      verifyState(person) {
        const currentEmail = person.email.trim();
        const isValid =
          currentEmail !== '' &&
          this.peopleList.filter((e) => e.email && e.email === currentEmail).length > 1 === false
            ? null
            : false;
        return this.isToValidate ? isValid : null;
      },
      fieldsValidate() {
        const allEmailsValids =
          !this.peopleList.find((e) => e.email.trim() === '') && this.flagEqualEmailName === false
            ? true
            : false;

        return allEmailsValids;
      },
      picturePlaceholder(first_name, last_name) {
        const firstLetter = (str) => String(str)?.[0]?.toUpperCase() ?? '';
        return `${firstLetter(first_name)}${firstLetter(last_name)}`;
      },
      onSave() {
        this.clearValidations();
        this.isToValidate = true;
        const isValid = this.fieldsValidate();
        if (!isValid) return;
      }
    },
    computed: {
      ...mapState('profile', {
        isModalAttentionEmail: 'isModalAttentionEmail'
      })
    },
    watch: {
      isModalAttentionEmail(v) {
        this.isOpen = v;
        if (!v) {
          this.clearValidations();
          this.clearFields();
          this.isToValidate = false;
        }
      }
    }
  };
</script>
<style lang="scss">
  .swal-conection.modal-attention-email {
    .swal2-modal {
      padding: 0 !important;
      border-radius: 6px;
      max-width: 746px;
      width: 100%;

      .swal2-header {
        background-color: #eceff1;
        padding: 20px 16px;
        border-radius: 6px 6px 0px 0px;
        flex-direction: row;
        justify-content: left;

        .swal2-title {
          color: #4c4541;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          margin: 0;
        }

        .swal2-close {
          border-radius: 6px;
          width: 34px;
          background-color: #ffff;
          height: 34px;
          top: -5px;
          position: absolute;
          right: -5px;
          padding-bottom: 5px;
          padding-left: 2px;

          &:hover {
            color: #4c4541;
          }
        }
      }

      .swal2-content {
        padding: 20px 16px 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .subtitle {
          text-align: left;
          color: #998f8a;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          padding-bottom: 20px;
        }

        .people-list {
          .error-message {
            padding: 15px;
            display: flex;
            align-items: center;
            border-radius: 6px;
            border: 1px solid #b71c1c;
            background: #ffebee;
            margin-bottom: 20px;
            color: #b71c1c;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }

          .people-block {
            max-height: 248px;
            height: 100%;
            overflow-y: scroll;

            &.is-invalid {
              max-height: 176px;
            }
            .person-row {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-block: 7.5px;
              border-bottom: 1px solid #eee;

              .person-info {
                display: flex;
                width: 100%;
                gap: 8px;

                .picture img {
                  width: 40px;
                  border-radius: 50%;
                }

                .text-block {
                  overflow: hidden;
                  .people-name {
                    color: #4c4541;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    white-space: nowrap;
                    word-break: break-all;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                  .people-code {
                    color: #998f8a;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    text-align: left;
                  }
                }
              }

              .person-email {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                padding-right: 16px;
                input {
                  max-width: 292px;
                  width: 100%;
                }
              }
            }

            &::-webkit-scrollbar {
              width: 7px;
            }

            &::-webkit-scrollbar-track {
              background: #fbeee8;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #cfc4be;
              border-radius: 9px;
            }
          }
        }

        label {
          text-align: start;
        }

        .form-control.is-invalid,
        .form-control.is-valid {
          background-image: none !important;
        }
        .form-control {
          &::placeholder {
            color: #cfc4be;
          }
          &:focus {
            border-color: #974900;
          }
        }
        .invalid-feedback {
          text-align: start !important;
        }

        p {
          text-align: start;
          margin-bottom: 16px;
        }

        .swal2-html-container {
          font-weight: 400;
          text-align: left;
          font-size: 14px;
          line-height: 20px;
          color: #4c4541;
          display: flex !important;
        }
      }

      .swal2-actions {
        padding: 20px 16px;
        margin-top: 0;
        display: flex;
        justify-content: right;

        .swal2-confirm {
          margin-left: 10px !important;
          order: 2;
          padding-block: 12px;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #ffff;
          border-color: #974900 !important;
          background-color: #974900 !important;
          border-radius: 5px;

          &:hover {
            box-shadow: none !important;
          }

          &:disabled {
            color: #7e7570 !important;
            font-size: 17px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.213px;
            background: #ece0db !important;
            border-color: #ece0db !important;
          }
        }

        .swal2-cancel {
          order: 1;
          padding-block: 12px;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #974900 !important;
          border-radius: 5px;
          border-color: transparent !important;

          &:hover {
            background-color: #ffede1 !important;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .swal-conection.modal-attention-email {
      &.swal2-container {
        padding: 30px;
      }
      .swal2-modal {
        max-width: 710px;
      }
    }
  }

  @media (max-width: 480px) {
    .swal-conection.modal-attention-email {
      &.swal2-container {
        padding: 16px;
      }
      .swal2-modal {
        .swal2-content {
          .subtitle {
            font-size: 12px;
            line-height: 16px;
          }
          .people-list {
            .error-message {
              font-size: 12px;
              line-height: 16px;
            }
            .people-block {
              max-height: 232px;
              &.is-invalid {
                max-height: 148px;
              }
              .person-row {
                flex-direction: column;
                gap: 8px;
                .person-info,
                .person-email {
                  padding-inline: 16px;

                  input {
                    max-width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
