var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.list),function(item){return _c('div',{key:item.id},[_c('div',{class:[item.type !== 'module' ? 'd-flex --is-module' : '']},[(item.type === 'system')?_c('div',{class:['system-block', item.type !== 'module' ? 'w-100' : '']},[_c('div',{class:['checkbox-div checkbox-container', _vm.allChildrenChecked(item) ? 'checked' : ''],on:{"click":function($event){return _vm.toggleSystemCheckbox(item)}}},[_c('span',{staticClass:"icon-checkbox"},[_c('CheckIcon')],1),_c('div',{class:['square', _vm.verifyHasChildrenChecked(item) ? '--is-active' : '']})]),(item.name === 'mysfc')?_c('MySfcLogo'):_vm._e(),(item.name === 'myconnect')?_c('MyConnectLogo'):_vm._e(),(item.name === 'myconfig')?_c('MyConfigLogo'):_vm._e()],1):_vm._e(),_c('div',{class:[item.type == 'module' ? '--is-module' : 'w-50']},[(item.type == 'module')?_c('div',{staticClass:"module-block w-100"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.displayName)+" ")])]):_vm._e(),(item.children.length === 0)?_c('div',{class:['page-block', item.type !== 'module' ? '--not-module' : '']},[_c('div',{staticClass:"all-checkbox"},[(_vm.verifyIfExist(item.options, 'show'))?_c('div',{staticClass:"checkbox-block show"},[_c('div',{class:[
                  'checkbox-container',
                  _vm.verifyIfChecked(item, 'show') ? 'checked' : '',
                  '__no-action-default'
                ],on:{"click":function($event){return _vm.toggleTypeCheckbox(item, 'show')}}},[_c('span',{staticClass:"icon-checkbox"},[_c('CheckIcon')],1)])]):_vm._e(),(_vm.verifyIfExist(item.options, 'update'))?_c('div',{staticClass:"checkbox-block update"},[_c('div',{class:[
                  'checkbox-container',
                  _vm.verifyIfChecked(item, 'update') ? 'checked' : '',
                  '__no-action-default'
                ],on:{"click":function($event){return _vm.toggleTypeCheckbox(item, 'update')}}},[_c('span',{staticClass:"icon-checkbox"},[_c('CheckIcon')],1)])]):_vm._e(),(_vm.verifyIfExist(item.options, 'create'))?_c('div',{staticClass:"checkbox-block create"},[_c('div',{class:[
                  'checkbox-container',
                  _vm.verifyIfChecked(item, 'create') ? 'checked' : '',
                  '__no-action-default'
                ],on:{"click":function($event){return _vm.toggleTypeCheckbox(item, 'create')}}},[_c('span',{staticClass:"icon-checkbox"},[_c('CheckIcon')],1)])]):_vm._e(),(_vm.verifyIfExist(item.options, 'delete'))?_c('div',{staticClass:"checkbox-block delete"},[_c('div',{class:[
                  'checkbox-container',
                  _vm.verifyIfChecked(item, 'delete') ? 'checked' : '',
                  '__no-action-default'
                ],on:{"click":function($event){return _vm.toggleTypeCheckbox(item, 'delete')}}},[_c('span',{staticClass:"icon-checkbox"},[_c('CheckIcon')],1)])]):_vm._e()])]):_vm._e()])]),(_vm.forceRenderAccessList)?_c('modal-access',{attrs:{"list":item.children,"initialList":_vm.initialList}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }