<template>
  <div>
    <div
      v-for="item in list"
      :key="item.id"
    >
      <div :class="[item.type !== 'module' ? 'd-flex --is-module' : '']">
        <div
          v-if="item.type === 'system'"
          :class="['system-block', item.type !== 'module' ? 'w-100' : '']"
        >
          <div
            :class="['checkbox-div checkbox-container', allChildrenChecked(item) ? 'checked' : '']"
            @click="toggleSystemCheckbox(item)"
          >
            <span class="icon-checkbox"><CheckIcon /></span>
            <div :class="['square', verifyHasChildrenChecked(item) ? '--is-active' : '']"></div>
          </div>
          <MySfcLogo v-if="item.name === 'mysfc'" />
          <MyConnectLogo v-if="item.name === 'myconnect'" />
          <MyConfigLogo v-if="item.name === 'myconfig'" />
        </div>

        <div :class="[item.type == 'module' ? '--is-module' : 'w-50']">
          <div
            v-if="item.type == 'module'"
            class="module-block w-100"
          >
            <div class="name">
              {{ item.displayName }}
            </div>
          </div>
          <div
            v-if="item.children.length === 0"
            :class="['page-block', item.type !== 'module' ? '--not-module' : '']"
          >
            <div class="all-checkbox">
              <div
                v-if="verifyIfExist(item.options, 'show')"
                class="checkbox-block show"
              >
                <div
                  :class="[
                    'checkbox-container',
                    verifyIfChecked(item, 'show') ? 'checked' : '',
                    '__no-action-default'
                  ]"
                  @click="toggleTypeCheckbox(item, 'show')"
                >
                  <span class="icon-checkbox"><CheckIcon /></span>
                </div>
              </div>
              <div
                v-if="verifyIfExist(item.options, 'update')"
                class="checkbox-block update"
              >
                <div
                  :class="[
                    'checkbox-container',
                    verifyIfChecked(item, 'update') ? 'checked' : '',
                    '__no-action-default'
                  ]"
                  @click="toggleTypeCheckbox(item, 'update')"
                >
                  <span class="icon-checkbox"><CheckIcon /></span>
                </div>
              </div>
              <div
                v-if="verifyIfExist(item.options, 'create')"
                class="checkbox-block create"
              >
                <div
                  :class="[
                    'checkbox-container',
                    verifyIfChecked(item, 'create') ? 'checked' : '',
                    '__no-action-default'
                  ]"
                  @click="toggleTypeCheckbox(item, 'create')"
                >
                  <span class="icon-checkbox"><CheckIcon /></span>
                </div>
              </div>
              <div
                v-if="verifyIfExist(item.options, 'delete')"
                class="checkbox-block delete"
              >
                <div
                  :class="[
                    'checkbox-container',
                    verifyIfChecked(item, 'delete') ? 'checked' : '',
                    '__no-action-default'
                  ]"
                  @click="toggleTypeCheckbox(item, 'delete')"
                >
                  <span class="icon-checkbox"><CheckIcon /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <modal-access
        v-if="forceRenderAccessList"
        :list="item.children"
        :initialList="initialList"
      />
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';

  export default {
    name: 'modal-access',
    data() {
      return {
        forceRenderAccessList: true
      };
    },
    components: {
      MySfcLogo: () => import('@core/assets/icons/my-sfc-logo.svg'),
      MyConnectLogo: () => import('@core/assets/icons/my-connect-logo.svg'),
      MyConfigLogo: () => import('@core/assets/icons/my-config-logo.svg'),
      CheckIcon: () => import('@core/assets/icons/check-icon.svg')
    },
    props: {
      list: {
        type: Array,
        required: true,
        default: []
      },
      initialList: {
        type: Array,
        default: []
      }
    },
    mounted() {},
    methods: {
      ...mapMutations('profile', ['UPDATE_PERMISSIONS_IDS']),
      toggleSystemCheckbox(elem) {
        if (!elem.checked) {
          elem['checked'] = true;
          this.allChildrenAction(elem, true);
        } else {
          elem['checked'] = false;
          this.allChildrenAction(elem, false);
        }
        this.$emit('forceRenderListAccess');
        this.forceRenderListAccess();
      },
      allChildrenChecked(item) {
        return item.optionsIds?.every((id) => {
          const allChildrenChecked = this.permissions.find((obj) => obj === id);
          return allChildrenChecked ? true : false;
        });
      },
      verifyHasChildrenChecked(item) {
        let arr = [];
        item.optionsIds.map((id) => {
          const checkedItems = this.permissions.includes(id);
          arr.push(checkedItems);
        });
        return arr.includes(true);
      },
      verifyIfChecked(item, type) {
        const hasCheckbox = item.options.find((e) => e.type === type);
        return hasCheckbox ? this.permissions.includes(hasCheckbox.id) : false;
      },
      toggleTypeCheckbox(item, type) {
        item.options.map((e) => {
          if (e.type === type) {
            const newIds = this.permissions.slice();
            const index = newIds.indexOf(e.id);

            if (index !== -1) {
              newIds.splice(index, 1);
            } else {
              newIds.push(e.id);
            }

            this.UPDATE_PERMISSIONS_IDS(newIds);
          }
        });
        this.forceRenderListAccess();
      },
      verifyIfExist(list, type) {
        const allTypes = list.map((e) => e.type);
        return allTypes.includes(type) ? true : false;
      },
      allChildrenAction(item, bool) {
        if (bool) {
          let idList = this.permissions;
          const addAllSfcOptions = item.optionsIds.filter((id) => !this.permissions.includes(id));
          addAllSfcOptions.map((id) => {
            idList.push(id);
          });
          this.UPDATE_PERMISSIONS_IDS(idList);
        } else {
          const removeAllSfcOptions = this.permissions.filter(
            (id) => !item.optionsIds.includes(id)
          );
          this.UPDATE_PERMISSIONS_IDS(removeAllSfcOptions);
        }
      },
      forceRenderListAccess() {
        this.forceRenderAccessList = false;
        this.$nextTick().then(() => {
          this.forceRenderAccessList = true;
        });
      }
    },
    computed: {
      ...mapState('profile', {
        isModalAccessOpen: 'isModalAccessOpen',
        permissions: 'permissions'
      })
    },
    watch: {
      isModalAccessOpen(v) {
        if (!v) {
          this.$emit('allCheckedToFalse');
          this.forceRenderListAccess();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .--is-module {
    display: flex;
    background-color: #f7f3ef;
  }
  .--not-module {
    height: 100%;
    width: 100% !important;
    .all-checkbox {
      .checkbox-block {
        margin-left: -2px;
      }
    }
  }
  .system-block,
  .module-block {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #f7f3ef;
    border-bottom: 1px solid #eee;
    padding: 4px 16px;

    .checkbox-div {
      cursor: pointer;
      width: 18px;
      height: 18px;
      border: 1px solid #cfc4be;
      background: #fff;
      border-radius: 3px;
      margin-block: 3px;
      .square {
        &.--is-active {
          width: 0;
          height: 0;
          border: 8px solid;
          border-color: transparent #974900 #974900 transparent;
          border-radius: 2px;
        }
      }
      &.checkbox-container {
        display: flex;
        background-color: #fff;
        border: 1px solid #cfc4be;
        border-radius: 3px;
        cursor: pointer;
        width: 18px;
        height: 18px;

        .icon-checkbox {
          display: none;
          align-items: center;
          justify-content: center;
          margin-top: 2px;
          svg {
            width: 12px;
            height: 12px;
          }
        }
        &.checked {
          background-color: #974900;
          display: block;

          .square {
            display: none;
          }

          .icon-checkbox {
            display: flex;
          }
        }
      }
    }

    .name {
      padding-block: 2.5px;
      color: #998f8a;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  .page-block {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f7f3ef;
    border-bottom: 1px solid #eee;
    gap: 8px;
    padding: 4px 8px 4px 0;

    .name {
      width: 100%;
      padding-block: 2.5px;
      color: #998f8a;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }

    .all-checkbox {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      gap: 10px;

      .checkbox-block {
        display: flex;
        justify-content: center;
        min-width: 55px;

        .checkbox-container {
          display: flex;
          background-color: #fff;
          border: 1px solid #cfc4be;
          border-radius: 3px;
          cursor: pointer;
          width: 18px;
          height: 18px;

          .icon-checkbox {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2px;
            svg {
              width: 12px;
              height: 12px;
            }
          }
          &.checked {
            background-color: #974900;
            display: block;
          }
        }
      }
    }
  }
</style>
