<template>
  <div>
    <modal-attention-email :device="device" />
    <modal-access
      :device="device"
      :profileSelected="profileSelected"
      @updatePermissions="getProfiles"
    />
    <modal-actions
      :device="device"
      :modalTitle="modalTitle"
      :profileSelected="profileSelected"
      :type="typeModal"
      @updateList="updateList"
    />
    <modal-people
      :modalTitle="modalPeopleName"
      :currentId="currentId"
      @updateList="updateList"
    />
    <div id="profiles-page">
      <div
        v-if="list.length === 0 && !noItemsFound"
        class="h-100"
      >
        <div class="no-items h-100">
          <EmptyBoxIcon class="empty-box" />
          <p class="title">{{ $t('ProfilesPage.NoProfileTitle') }}</p>
          <p class="subtitle">{{ $t('ProfilesPage.NoProfileSubtitle') }}</p>
          <b-button
            class="btn-new-profile"
            @click="openModal('new')"
          >
            <AddIcon class="add-icon" />
            {{ $t('ProfilesPage.NewProfile') }}
          </b-button>
        </div>
      </div>
      <div v-else>
        <div class="header-block">
          <div class="title">
            {{ $t('PagesViews.Profiles') }}
            <b-button
              class="btn-new-profile"
              @click="openModal('new')"
            >
              <AddIcon class="add-icon" />
              {{ $t('ProfilesPage.NewProfile') }}
            </b-button>
          </div>
          <div class="search-input">
            <b-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="div-custom-input input__profiles"
            >
              <div class="icon-block">
                <SearchIcon class="search-icon" />
              </div>
              <b-form-tags
                class="input-search-profiles"
                ref="formTagProfile"
                v-model="filter.textFilter"
                duplicate-tag-text=""
                :placeholder="filter.textFilter.length === 0 ? $t('SearchPlaceholderDefault') : ''"
                @input="getProfiles()"
                remove-on-delete
              />
            </b-col>
          </div>
        </div>
        <div class="separator"></div>
        <div
          :class="list.length === 0 ? 'no-items' : 'profiles-list-container'"
          ref="scrollContainer"
          @scroll="handleScroll"
        >
          <profiles-list
            :list="list"
            :prefix="prefix"
            @deletedProfile="updateList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ModalPeople from './components/peopleModals/ModalPeople.vue';
  import { mapMutations, mapState } from 'vuex';
  import { BFormTags, BButton, BCol } from 'bootstrap-vue';
  import EmptyBoxIcon from '@core/assets/icons/empty-box-icon.svg';
  import AddIcon from '@core/assets/icons/add-icon.svg';
  import profilesList from './components/profiles-list.vue';
  import ModalActions from './components/ModalActions.vue';
  import ModalAccess from './components/access-components/ModalAccess.vue';
  import ModalAttentionEmail from './components/ModalAttentionEmail.vue';

  export default {
    components: {
      BFormTags,
      BButton,
      BCol,
      AddIcon: () => import('@core/assets/icons/add-icon.svg'),
      CloseIcon: () => import('@core/assets/icons/close-icon.svg'),
      SearchIcon: () => import('@core/assets/icons/search-icon.svg'),
      AddIcon,
      EmptyBoxIcon,
      profilesList,
      ModalActions,
      ModalAccess,
      ModalPeople,
      ModalAttentionEmail
    },
    data() {
      return {
        filter: {
          textFilter: []
        },
        sidebarVisible: false,
        prefix: localStorage.getItem('prefix'),
        noItemsFound: false,
        list: [],
        device:
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 480 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile',
        modalTitle: '',
        profileSelect: null,
        typeModal: '',
        modalPeopleName: '',
        currentId: null
      };
    },
    created() {
      window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
      this.getProfiles();
      this.getFuntions();
      this.UPDATE_MODAL_EDIT_DELETE_TOGGLE(false);
      this.UPDATE_MODAL_ATTENTION_EMAIL(true);
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('profile', [
        'UPDATE_MODAL_EDIT_DELETE_TOGGLE',
        'UPDATE_TYPE_MODAL',
        'UPDATE_ROLES_LIST',
        'UPDATE_PEOPLE_LIST',
        'UPDATE_PEOPLE_TO_LINK_LIST',
        'UPDATE_FIRST_CREATION_LIST',
        'UPDATE_MODAL_ATTENTION_EMAIL'
      ]),
      activeCarousel() {
        const vm = this;
        $(function () {
          document
            .getElementsByClassName('input-search-profiles')[0]
            .setAttribute('tabIndex', '-1');

          const slider = document.querySelector('.b-form-tags-list');
          let mouseDown = false;
          let startX, scrollLeft;

          let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
          };
          let stopDragging = function () {
            mouseDown = false;
          };

          slider.addEventListener('mousemove', (e) => {
            e.preventDefault();
            if (!mouseDown) {
              return;
            }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
          });

          slider.addEventListener('mousedown', startDragging, false);
          slider.addEventListener('mouseup', stopDragging, false);
          slider.addEventListener('mouseleave', stopDragging, false);

          $('.b-form-tags-input').blur((event) => {
            let input = vm.$refs.formTagProfile;
            input.addTag(event.target.value);
          });
        });
      },
      openModal(type) {
        this.UPDATE_TYPE_MODAL(type);
        this.UPDATE_MODAL_EDIT_DELETE_TOGGLE(true);
      },
      handleScroll() {
        const container = this.$refs.scrollContainer;
        if (Math.ceil(container.scrollTop) + container.clientHeight >= container.scrollHeight) {
          this.loadMoreItems();
        }
      },
      handleResize() {
        this.device =
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 480 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile';
      },
      loadMoreItems() {
        const lastId = this.list[this.list.length - 1].id;
        this.getProfiles(lastId);
      },
      updateList(newProfile) {
        if (newProfile) {
          this.currentId = newProfile.id;
          this.modalPeopleName = newProfile.name;
        }
        this.getProfiles();
      },
      async getProfiles(id = 0) {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        const textFilterQuery = this.filter.textFilter
          .map((filter) => `${filter}`)
          .join('&text_filter=');
        await this.$http
          .get(`/api/${this.prefix}/myconfig/profile?id=${id}&text_filter=${textFilterQuery}`)
          .then((response) => {
            textFilterQuery !== '' ? (this.noItemsFound = true) : (this.noItemsFound = false);
            id !== 0 ? this.list.push(...response.data.data) : (this.list = response.data.data);
            !this.isFirstCreation && this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
        this.activeCarousel();
      },
      async getFuntions() {
        await this.$http2.get('api/myconfig/function/context').then((response) => {
          this.UPDATE_ROLES_LIST(response.data.data.functions);
        });
      }
    },
    computed: {
      ...mapState('profile', {
        isModalEditRenameDeleteOpen: 'isModalEditRenameDeleteOpen',
        profileSelected: 'profileSelected',
        typeModalStore: 'typeModalStore',
        isFirstCreation: 'isFirstCreation'
      }),
      displayImportantFirst() {
        return this.customFields.sort((a, b) => {
          if (a.important && !b.important) {
            return -1;
          }
          if (!a.important && b.important) {
            return 1;
          }
          return 0;
        });
      }
    },
    watch: {
      profileSelected() {
        this.modalPeopleName = this.profileSelected.name;
        this.currentId = this.profileSelected.id;
        this.profileSelect = this.profileSelected;
      },
      typeModalStore() {
        if (this.typeModalStore === 'edit') {
          this.modalTitle = 'ProfilesPage.EditProfile';
        }
        if (this.typeModalStore === 'duplicate') {
          this.modalTitle = 'ProfilesPage.DuplicateProfile';
        }
        if (this.typeModalStore === 'new') {
          this.modalTitle = 'ProfilesPage.NewProfile';
        }
        this.typeModal = this.typeModalStore;
      }
    }
  };
</script>
<style lang="scss">
  #profiles-page {
    padding: 16px 0;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);

    @media (max-width: 768px) {
      margin-top: 24px;
    }

    &:has(.no-items) {
      height: calc(100vh - 170px);
    }

    .no-items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      margin: 0 16px;
      border: 1px solid #cfc4be;

      .empty-box {
        width: 80px;
        height: 80px;
      }

      .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        color: #4c4541;
        margin: 0;
      }

      .subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #998f8a;
        padding: 4px 0 12px;
        margin: 0;
      }

      .btn-new-profile {
        border-color: #974900 !important;
        background-color: #974900 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 8px 21px;
        line-height: 20px;
        letter-spacing: 0.175px;

        .add-icon {
          width: 14px;
          height: 14px;
          fill: #fff;
        }
      }

      @media (max-width: 480px) {
        .empty-box {
          width: 40px;
          height: 40px;
        }

        .title {
          font-size: 14px;
        }

        .subtitle {
          font-size: 12px;
          text-align: center;
          padding: 4px 48px 12px;
          line-height: 16px;
        }

        .btn-new-profile {
          padding: 4px 14px;
        }
      }
    }

    .header-block {
      padding: 0 16px;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;

        .btn-new-profile {
          border-color: #974900 !important;
          background-color: #974900 !important;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          padding: 3px 14px !important;
          line-height: 20px;
          letter-spacing: 0.175px;

          .add-icon {
            width: 14px;
            height: 14px;
            fill: #fff;
          }
        }
      }

      .search-input {
        display: flex;
        align-items: center;
        padding: 16px 0 8px;

        @media (max-width: 580px) {
          flex-direction: column;
          gap: 8px;
        }

        .div-custom-input {
          display: flex;
          align-items: center;
          border-radius: 4px;
          width: 100%;
          overflow: hidden;
          border: 1px solid #cfc4be;

          .icon-block {
            display: flex;
            align-items: center;
            height: 14px;

            .search-icon {
              width: 14px;
              height: 14px;
              fill: #cfc4be;
            }
          }

          .input-search-profiles {
            width: 100% !important;
            border: none;
            overflow: hidden;

            &.focus {
              box-shadow: none;
            }
            &.b-form-tags {
              padding: 0 !important;
              display: flex;
              align-items: center !important;
              .b-form-tags-list {
                width: 100% !important;
                margin-left: 14px !important;
                margin-top: 0;
                height: 100% !important;
                flex-wrap: nowrap !important;
                display: flex !important;
                align-items: center !important;
                overflow: scroll;
                overflow-y: hidden;
                cursor: grabbing;
                cursor: -webkit-grabbing;

                .b-form-tags-field {
                  margin: 9px 0;
                }

                &::-webkit-scrollbar {
                  display: none !important;
                }
              }
            }

            .b-form-tags-button {
              display: none;
            }

            .b-form-tag {
              background-color: #974900;
              margin-right: 0.6rem;
              padding: 2px 8px !important;
              font-size: 13px;
            }
          }
        }

        .add-profile {
          padding-left: 8px !important;
          padding-right: 0 !important;

          @media (max-width: 580px) {
            max-width: 100% !important;
            padding-left: 0 !important;
            flex: 0 0 100% !important;
            width: 100% !important;
          }
        }
      }
    }

    .separator {
      margin-top: 16px;
      margin-bottom: 16px;
      width: 100%;
      height: 1px;
      background: #eee;
    }

    .profiles-list-container {
      max-height: 365px;
      overflow: auto;
      margin-right: 8px;

      &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 6px;
        background-color: #fbeee8;
      }

      &::-webkit-scrollbar-track:hover {
        background-color: #fbeee8;
      }

      &::-webkit-scrollbar-track:active {
        background-color: #fbeee8;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: #cfc4be;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #cfc4be;
      }

      &::-webkit-scrollbar-thumb:active {
        background-color: #cfc4be;
      }
    }

    .no-items {
      min-height: 365px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .body-block {
      padding: 0 16px;
      margin: 0 14px;
      overflow-x: auto;

      &::-webkit-scrollbar {
        height: 3px;
      }

      &::-webkit-scrollbar-track {
        background: #fff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #cfc4be;
        border-radius: 9px;
      }
    }
  }
</style>
